import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import authReducer from '@shared/redux/slices/auth/storage-slice';
import authSessionReducer from '@shared/redux/slices/auth/session-slice';
import vehicleFiltersReducer from '@shared/redux/slices/vehicle/filters';
import chargingStationReducer from '@shared/redux/slices/charging-station/filters';
import trackersFilterReducer from '@shared/redux/slices/tracker/filters';
import logsFiltersReducer from '@shared/redux/slices/logs/filters';

import { baseApi } from '@shared/api';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const authSessionPersistConfig = {
  key: 'authSession',
  storage: storageSession,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  authSession: persistReducer(authSessionPersistConfig, authSessionReducer),
  vehicleFilters: vehicleFiltersReducer,
  stationsFilters: chargingStationReducer,
  trackersFilters: trackersFilterReducer,
  logsFilters: logsFiltersReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
