import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RootLayout } from '@shared/ui/root-layout';
import {
  ANALYTICS,
  CHARGE_POINTS,
  CHARGING_STATIONS,
  COUNTERPARTIES,
  DRIVERS,
  ROUTES,
  SCHEDULE,
  SETTINGS,
  TELEMETRY,
  TRACKERS,
  TRANSPORT,
  ELECTROBUS_PARK,
  CONVOY,
  CONVOY_TABLE,
  ELECTROBUS,
  ELECTROBUS_DETAILS,
  PARK_TABLE,
  USERS,
  LOGS,
} from '@shared/consts/routes';
import { selectCurrentToken } from '@shared/hooks/select-current-token';

import { SiderLayout } from '@widgets/sider/ui/layout';
import { useAppSelector } from '@app/hooks';

import { TransportPage } from '@pages/vehicles';
import { DriversPage } from '@pages/drivers';
import { SchedulePage } from '@pages/schedule';
import { RoutesPage } from '@pages/routes';
import { AnalyticsPage } from '@pages/analytics';
import { SettingsPage } from '@pages/settings';
import { LoginPage } from '@pages/login';
import { TrackersPage } from '@pages/trackers';
import { TelemetryPage } from '@pages/telemetry';
import { ChargingStationsPage } from '@pages/charging-stations';
import { CounterpartiesPage } from '@pages/counterparties';
import { UsersPage } from '@pages/users';
import { ElectrobusInfoPage } from '@pages/electrobus-info';
import { ParkOfElectrobusesPage } from '@pages/park-of-electrobuses';
import { RootPage } from '@pages/root-layout-page';
import { LogsPage } from '@pages/logs';

export function AppRouter() {
  const location = useLocation();

  const token = useAppSelector(selectCurrentToken);

  const renderPrivateRoutes = () => {
    return (
      <RootLayout sidebar={<SiderLayout />}>
        <Routes>
          <Route index element={<RootPage />} />
          <Route path={TRANSPORT} element={<TransportPage />} />
          <Route path={ELECTROBUS_PARK} element={<ParkOfElectrobusesPage />} />
          <Route path={PARK_TABLE} element={<TransportPage />} />
          <Route path={CONVOY} element={<ParkOfElectrobusesPage />} />
          <Route path={CONVOY_TABLE} element={<TransportPage />} />
          <Route path={ELECTROBUS} element={<ParkOfElectrobusesPage />} />
          <Route path={ELECTROBUS_DETAILS} element={<ElectrobusInfoPage />} />
          <Route path={DRIVERS} element={<DriversPage />} />
          <Route path={SCHEDULE} element={<SchedulePage />} />
          <Route path={ROUTES} element={<RoutesPage />} />
          <Route path={ANALYTICS} element={<AnalyticsPage />} />
          <Route path={SETTINGS} element={<SettingsPage />} />
          <Route path={TRACKERS} element={<TrackersPage />} />
          <Route path={TELEMETRY} element={<TelemetryPage />} />
          <Route path={CHARGING_STATIONS} element={<ChargingStationsPage />} />
          <Route path={COUNTERPARTIES} element={<CounterpartiesPage />} />
          <Route path={USERS} element={<UsersPage />} />
          <Route path={LOGS} element={<LogsPage />} />
        </Routes>
      </RootLayout>
    );
  };

  const renderPublicRoutes = () => {
    return (
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="unauthorized" element={<div>Нет прав</div>} />
        <Route
          path="*"
          element={<Navigate to="/login" state={{ from: location }} replace />}
        />
      </Routes>
    );
  };

  return token ? renderPrivateRoutes() : renderPublicRoutes();
}
